import React from 'react';
import DataWrapChart from '../datawrap';
import { Button } from 'theme-ui'
import { useState } from 'react'


// defined two iframes
const srcs = {'MPD':'https://datawrapper.dwcdn.net/lHhbA/2/', 'Suburban':'https://datawrapper.dwcdn.net/7k1N9/2/'}

const tabColor = {
  0:['white','#07648d'],
  1:['#07648d', 'white'],
            }

function Offense() {


    // set initial state
    const [selectedSrc, setSrc] = useState(srcs['MPD']);

    // set up left button state
    const [lbColor, setLBColor] = useState(tabColor[0]);

    //set up right button state
    const [rbColor, setRBColor] = useState(tabColor[1]);

    function handleLBClick(){
    setSrc(srcs['MPD']);
    setLBColor(tabColor[0]);
    setRBColor(tabColor[1]);
    };

    function handleRBClick(){
    setSrc(srcs['Suburban']);
    setLBColor(tabColor[1]);
    setRBColor(tabColor[0]);
    };

    
    return (  
        
          <section className="usa-graphic-list usa-section">

              
                  <div className='grid-container'>
                  <a name="ref_by_offense_type"></a>  <h1>Dispositions by Offense Type</h1>
                
                  <div><hr></hr></div>
                  
                  </div>



                  <div className='grid-container'>

                  
                  <Button 
          onClick={handleLBClick}
          
        sx={{
            appearance: 'none',
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: 'inherit',
            textDecoration: 'none',
            fontSize: '14px',
            fontWeight: 'bold',
            font: 'inherit',
            m: 0,
            px: 0,
            py: 0,
            borderRadius: 0,
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
            color: '#07648d',
            border: 'inherit',
            background: lbColor[1],
            borderWidth: '1px',
            borderStyle: 'solid',
            width: 90,
            height: 45,
            outline:'none',
            marginBottom: '6px'
            
        }}>
        <b style={{color:lbColor[0]}}><small>Misdemeanor</small></b>
      </Button>

      <Button 
          onClick={handleRBClick}
          
        sx={{
          appearance: 'none',
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: 'inherit',
          textDecoration: 'none',
          fontSize: '14px',
          fontWeight: 'bold',
          font: 'inherit',
          m: 0,
          px: 2,
          py: 2,
          borderRadius: 0,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          color: '#07648d',
          border: 'inherit',
          background:rbColor[1],
          borderWidth: '1px',
          borderStyle: 'solid',
          width: 70,
          height: 45,
          outline:'none',
          marginBottom: '6px',
        }}>
        <b style={{color:rbColor[0]}}>Felony</b>
      </Button>
                  </div>
                  <div className='grid-container'>
                  <DataWrapChart frameborder="0" aria-label="Table" id="datawrapper-chart-Fucs6"  height="500"  allowfullscreen="true" src={selectedSrc}/>
                  </div>


                  </section>
          



    );
  };



export default Offense;
