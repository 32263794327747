import React from 'react'


function RefNav() {

    return (

        <aside className="usa-layout-docs-sidenav padding-top-2 padding-bottom-4">
        <ul class="site-sidenav usa-sidenav ">
            <h3 class="padding-top-4 font-serif-md" > Report Categories </h3>
            
        <li class="usa-sidenav__item ">
          <a href="#ref_over_time"> Overall Trends</a>
          </li>
          <li class="usa-sidenav__item bold">
          <a href="#ref_by_offense_type"> Offense Type</a>
          </li>
          <li class="usa-sidenav__item bold">
          <a href="##dem_break"> Demographic Breakdown</a>
          </li>
            </ul>
          </aside>

    )
};

export default RefNav