import React from 'react';
import Layout from '../components/layout';
import DataWrapChart from '../components/datawrap';
import { graphql } from 'gatsby';

import RevNav from '../components/dispo/refSideNav';
import RefTrends from '../components/dispo/overallTrends';
import Offense from '../components/dispo/offenseType';
import DemTrends from '../components/dispo/demographics';

const DispoPage = ({ data }) => {
  const orderNodes = data.allIframeIdCsv.nodes;
  const url = orderNodes.map(node => node.id);
  const id = orderNodes.map(node => node.url);
  const obj = Object.create(null);
  url.forEach((e, i) => (obj[e] = id[i]));

  return (
    <Layout>
      <div className="grid-container">
        <div class="notification">
          <div class="columns">
            <div class="column is-3">
              <RevNav></RevNav>
            </div>

            <section className="usa-graphic-list usa-section">
              <div className="grid-container">
                <div class="border-bottom-1 border-accent-cool-darker  padding-bottom-2 margin-bottom-2 usa-prose">
                  <h1 className="text-bold margin-0">Disposition</h1>
                </div>
              </div>

              <div className="grid-container">
                <p>
                  A case disposition refers to how the case is resolved in
                  court. Case dispositions include Dismissals, Guilty Plea,
                  Deferred Prosecution Agreement, and Convictions.
                </p>
                <p>
                  Data updated to: <b>11-30-2023</b>
                </p>
              </div>
              <div className="grid-container">
                <DataWrapChart
                  frameborder="0"
                  aria-label="Table"
                  id="datawrapper-chart-Fucs6"
                  src="https://datawrapper.dwcdn.net/gYO8C/4/"
                />
              </div>

              <RefTrends></RefTrends>
              <Offense></Offense>
              <DemTrends></DemTrends>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query MyDispoQuery {
    allIframeIdCsv(filter: { page: { eq: "ref" } }) {
      nodes {
        id
        url
      }
    }
  }
`;

export default DispoPage;
